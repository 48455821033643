import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { ThemeProvider } from "styled-components";
import { lightTheme, GlobalStyles } from "./themes";

import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";

import React from "react";
import ReactGA from 'react-ga'

ReactGA.initialize('G-NF46HHRD0T');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles theme={lightTheme} />
        <Header />
        <Routes>
          <Route path="/" element={<Dashboard />} />
        </Routes>
        <Footer/>
      </ThemeProvider>
    </BrowserRouter>
  );
}
console.log(`
  /**
   *       
   *
   *  __        __          _____           _
   *  \\ \\      / /         | ___ \\         | |
   *   \\ \\    / / _  ____  | |_/ /__   ___ | |
   *    \\ \\  / / | |/  __| |  __/ _ \\ / _ \\| |
   *     \\ \\/ /  | |( (__  | | | (_) | (_) | |
   *      \\__/   |_| \\___| \\_|  \\___/ \\___/|_|
   * +---------------------------------------------------+
   * |    DECENTRALISED STAKING PROTOCOL FOR VICTION    |
   * +---------------------------------------------------+
   *
   *  VicPool is a first-of-its-kind Viction staking pool protocol, designed to
   *  be community-owned, decentralised, permissionless, & trustless.
   *
   *  For more information about VicPool, visit https://vicpool.fi
   *
   *  Contributors: VicPool team
   *
   */
`)

export default App;
