import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import {TiTick} from "react-icons/ti";
import {Container} from 'react-bootstrap';

export default function Header() {
  return (
    <div>
      <section className='py-5'>
        <Container>
          <div className='special-bg-right'>
            <h3 className='h1 mb-4 title-underline'>Compare stake</h3>
            <Row>
              <Col xs={12} md={6}>
                <h4 className='h3 mb-4'>VicPool</h4>
                <ListGroup>
                  <ListGroup.Item className='py-3'><TiTick className='green-color mx-1'/>
                    Minimum stake $VIC 10
                  </ListGroup.Item>
                  <ListGroup.Item className='py-3'><TiTick className='green-color mx-1'/>
                    80% block rewards share to stakers.
                  </ListGroup.Item>
                  <ListGroup.Item className='py-3'><TiTick className='green-color mx-1'/>
                    Daily reward distribute
                  </ListGroup.Item>
                  <ListGroup.Item className='py-3'><TiTick className='green-color mx-1'/>
                    Decentralized ownership between all voters
                  </ListGroup.Item>
                  <ListGroup.Item className='py-3'><TiTick className='green-color mx-1'/>
                    2 days liquidity unless unlikely event of stakers deciding to resign (66% agreement).
                    If there is resign, portion takes 30 days to unstake.
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col xs={12} md={6}>
                <h4 className='h3 mb-4'>VicMaster</h4>
                <ListGroup>
                  <ListGroup.Item className='py-3'><TiTick className='blue-color mx-1'/>
                    Minimum stake $VIC 100
                  </ListGroup.Item>
                  <ListGroup.Item className='py-3'><TiTick className='blue-color mx-1'/>
                    50% block rewards share to stakers
                  </ListGroup.Item>
                  <ListGroup.Item className='py-3'><TiTick className='blue-color mx-1'/>
                    30 minutes reward distribute
                  </ListGroup.Item>
                  <ListGroup.Item className='py-3'><TiTick className='blue-color mx-1'/>
                    One individual or team of owners.
                  </ListGroup.Item>
                  <ListGroup.Item className='py-3'><TiTick className='blue-color mx-1'/>
                    Guaranteed 2 days to unstake.
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className='py-5 box-shadow'>
      <Container>
        <div className='special-bg'>
          <h3 className='h1 mb-4 title-underline'>Compare VicPool</h3>
          <Row>
            <Col xs={12} md={6}>
              <h4 className='h3 mb-4'>Liquid Stake</h4>
              <ListGroup>
                <ListGroup.Item className='py-3'><TiTick className='green-color mx-1'/>
                  Dynamic pool staking
                </ListGroup.Item>
                <ListGroup.Item className='py-3'><TiTick className='green-color mx-1'/>
                  Manage by $sVIC token.
                </ListGroup.Item>
                <ListGroup.Item className='py-3'><TiTick className='green-color mx-1'/>
                  Rewards auto re-stake every balance greater than 10 $VIC
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col xs={12} md={6}>
              <h4 className='h3 mb-4'>Pool Stake</h4>
              <ListGroup>
                <ListGroup.Item className='py-3'><TiTick className='blue-color mx-1'/>
                  Manual choose pool to stake
                </ListGroup.Item>
                <ListGroup.Item className='py-3'><TiTick className='blue-color mx-1'/>
                  Users manage by themself
                </ListGroup.Item>
                <ListGroup.Item className='py-3'><TiTick className='blue-color mx-1'/>
                  Daily reward distributed (or manually withdrawal).
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    </div>
  );
}