import React from 'react';
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VicPool from '../../asset/logo512.png'

const Main = styled.main`  
  @media (min-width: 993px) {
    min-height: 648px;
    img {
      width: 75%;
    }
  }
  @media (max-width: 992px) {
    min-height: 576px;
    img {
      max-width: 65%;
    }
  }
  @media (max-width: 768px) {
    min-height: 480px;
    img {
      max-width: 60%;
    }
  }
`;
export default function Header() {
  return (
    <div className='box-shadow'>
      <Container>
        <Row className='special-bg'>
          <Col xs={12} sm={12} md={8} lg={6}>
            <Main className='d-flex align-content-center flex-wrap'>
              <Row>
              <h1 className='h1'>
                Viction decentralized staking
              </h1>
              </Row>
              <Row>
              <p>
                By depositing $VIC into VicPool, you will
                participate in Viction's Proof-of-Stake consensus mechanism (staking) and receive $VIC rewards in return.
              </p>
              </Row>
              <Row>
              <div className="gap-3 mt-5">
              <ButtonGroup>
                <Button className="green-button" href='https://app.vicpool.fi'>Liquid Stake</Button>
              </ButtonGroup>
              <ButtonGroup className='ms-3'>
                <Button variant="primary" href='https://stake.vicpool.fi'>Pool Stake</Button>
              </ButtonGroup>
            </div>
              </Row>
            </Main>
          </Col>
          <Col xs={12} sm={12} md={4} lg={6} className='none-display-576'>
            <Main className='d-flex align-content-center flex-wrap'>
              <img src={VicPool} alt='VicPool'/>
            </Main>
          </Col>
        </Row>
      </Container>

    </div>
  );
}