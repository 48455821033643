import React from 'react';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import khaihkd from '../../asset/khaihkd.png'
import campv from '../../asset/campv.png'
import cm from '../../asset/cm.png'
import {Container} from 'react-bootstrap';

export default function Header() {
  return (
    <section className='my-5'>
      <Container>
        <h3 className='h1 mb-5 title-underline'>Our team</h3>
        <Row>
          <Col md={6} lg={4}>
            <div className='text-center mb-4'>
              <div><Image src={khaihkd} width={250} height={250} roundedCircle/></div>
              <div className='py-3'>
                <h3 className='h3'>Minh Khai Do</h3>
                <p>General Manager</p>
              </div>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className='text-center mb-4'>
              <div><Image src={campv} width={250} height={250} roundedCircle/></div>
              <div className='py-3'>
                <h3 className='h3'>Dr. Cam Pham</h3>
                <p>Advisor</p>
              </div>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className='text-center mb-4'>
              <div><Image src={cm} width={250} height={250} roundedCircle/></div>
              <div className='py-3'>
                <h3 className='h3'>CM</h3>
                <p>Sales & Marketing</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}