import React from 'react';
import {Container} from 'react-bootstrap'
import styled from "styled-components";
import { FaGithub, FaTelegram, FaTwitter } from "react-icons/fa";

export const IconList = styled.div`
  a {
    color: rgba(255, 255, 255, 0.6);
    font-size: 30px;
    
    :hover {
      color: rgba(0, 197, 154, 0.8);
    }
  }
`;
export default function App() {
  return (
    <footer>
      <Container>
      <IconList className='text-center'>
        <ul className="list-inline">
          <li className="list-inline-item">
            <a href="https://t.me/vic_pool">
              <FaTelegram/>
            </a>
          </li>
          <li className="list-inline-item">
            <a href="https://twitter.com/vicpool_fi">
              <FaTwitter/>
            </a>
          </li>
          <li className="list-inline-item">
            <a href="https://github.com/vic-pool">
              <FaGithub/>
            </a>
          </li>
        </ul>
      </IconList>
      <div className='text-center p-3'>
        © {new Date().getFullYear()} Copyright:{' '}
        <a className='text-white' href='https://vicpool.fi/'>
          VicPool
        </a>
      </div>
      </Container>
    </footer>
  );
}