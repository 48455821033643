import React from 'react';
import {Container, Navbar} from 'react-bootstrap';
import Logo from '../../asset/vicpool.png'
import {NavLink} from "react-router-dom";
export default function Header() {

  return (
    <header>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand>
            <NavLink to='/'>
              <img src={Logo} alt='TomPool' height={'50px'}/>
            </NavLink>
          </Navbar.Brand>

        </Container>
      </Navbar>
    </header>
  );
}