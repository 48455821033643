import React from "react";

import Introduce from "../../components/Introduce";
import TVL from "../../components/TVL";
import Compare from "../../components/Compare";
import Team from "../../components/Team";
export default function Dashboard() {

  return (
    <div>
      <Introduce/>
      <TVL/>
      <Compare/>
      <Team/>
    </div>
  )
}