import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios'

function formatNumber(number: number) {
  let seps = number.toString().split('.')
  seps[0] = seps[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return seps.join('.')
}
export default function Header() {
  const [tvl, setTvl] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [rewardPaid, setRewardPaid] = useState(0);

  const getTVL = async () => {
    const {data} = await axios.get('https://api.vicpool.fi/stats/tvl')
    setTvl(Math.ceil(data.tvl))
    setTotalUser(data.totalUser)
    setRewardPaid(Math.ceil(data.rewardPaid))
  }
  useEffect(() => {
    getTVL()
  }, [])

  return (
    <section className='py-5 tvl-gradient'>
      <Container className='justify-content-center'>
        <div className='width-600'>
          <Row>
            <Col xs={12} md={4} className='tvl-amount'>
              <p>Total Value Locked</p>
              <p>$VIC {formatNumber(tvl)}</p>
            </Col>
            <Col xs={12} md={4} className='tvl-amount'>
              <p>Total Stakers</p>
              <p>{formatNumber(totalUser)}</p>
            </Col>
            <Col xs={12} md={4}>
              <p>Rewards paid</p>
              <p>$VIC {formatNumber(rewardPaid)}</p>
            </Col>
        </Row>
        </div>
      </Container>
    </section>
  )
}